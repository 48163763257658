import { endOfDay, format, isAfter, subDays } from 'date-fns'
import { fromZonedTime } from 'date-fns-tz'
import { useMemo } from 'react'
import { isTeamCercle } from 'utils'

import { MatchCalendarCard } from '../../../matches/components/match-calendar-card'
import { MatchHighlight } from '../../../matches/components/match-highlight'
import { MatchLiveCard } from '../../../matches/components/match-live-card'
import { MatchUpcoming } from '../../../matches/components/match-upcoming'
import { matchIsLive } from '../../../matches/utils/isMatchLive'
import { Loader } from '../../../shared/components/loader'
import { useStoryblokTranslationsContext } from '../../context/translations-provider'
import { useStoryblokStories } from '../../hooks/useStoryblokStories'
import { useTranslations } from '../../hooks/useTranslations'
import { getHrefFromStoryblokLink } from '../../utils/getHrefFromStoryblokLink'
import { getMatchesContent } from '../../utils/getMatchesContent'

import {
  StContainer,
  StFourth,
  StHalf,
  StLoadingContainer,
} from './MatchWidget.styled'

import type { StoryblokMatch } from '../../content-types/match'
import type { StoryblokTeam } from '../../content-types/team'
import type { ISbStoryData } from '@storyblok/react'
import type { StoryblokImage, StoryblokLink } from 'types'

const getNotCercleTeam = (
  homeTeam: string | ISbStoryData<StoryblokTeam> | undefined,
  awayTeam: string | ISbStoryData<StoryblokTeam> | undefined
) => {
  if (
    typeof homeTeam !== 'string' &&
    homeTeam?.content &&
    !isTeamCercle(homeTeam?.content?.name)
  ) {
    return homeTeam
  }
  if (
    typeof awayTeam !== 'string' &&
    awayTeam?.content &&
    !isTeamCercle(awayTeam?.content?.name)
  ) {
    return awayTeam
  }
  return undefined
}

const normalizeMatchContent = (content: StoryblokMatch[]) => {
  return content.map((match) => {
    const otherTeam = getNotCercleTeam(match.home_team, match.away_team)

    return {
      id: match?._uid,
      teamLogo: otherTeam?.content?.logo,
      teamName: otherTeam?.content?.name,
      teamNameShort: otherTeam?.content?.short_name,
      matchDate: match?.matchday || '',
      matchTime: match?.time,
      ticketLink: {
        href: getHrefFromStoryblokLink(match.tickets) ?? '',
      },
      playingHome:
        typeof match?.home_team === 'string'
          ? false
          : otherTeam?.uuid !== match.home_team?.uuid,
    }
  })
}

interface StoryblokMatchWidgetProps {
  blok: {
    _uid: string
    background: StoryblokImage
    highlighted_match_title?: string
    highlighted_match?: ISbStoryData<StoryblokMatch>
    highlighted_match_link?: StoryblokLink
    next_match_title?: string
    sponsor_image?: StoryblokImage
    sponsor_link?: StoryblokLink
    sponsor_live_image?: StoryblokImage
    sponsor_live_link?: StoryblokLink
    future_matches_title?: string
    future_matches_link?: StoryblokLink
  }
  relations?: ISbStoryData<StoryblokTeam>[]
}

export const MatchWidget = ({ blok }: StoryblokMatchWidgetProps) => {
  const { cercleTeam, highlightedTeam } = useMemo(() => {
    if (
      isTeamCercle(
        (
          blok.highlighted_match?.content
            ?.home_team as ISbStoryData<StoryblokTeam>
        )?.content?.name,
        (
          blok.highlighted_match?.content
            ?.home_team as ISbStoryData<StoryblokTeam>
        )?.content?.short_name
      )
    ) {
      return {
        cercleTeam: blok.highlighted_match?.content
          ?.home_team as ISbStoryData<StoryblokTeam>,
        highlightedTeam: blok.highlighted_match?.content
          ?.away_team as ISbStoryData<StoryblokTeam>,
      }
    } else if (
      isTeamCercle(
        (
          blok.highlighted_match?.content
            ?.away_team as ISbStoryData<StoryblokTeam>
        )?.content?.name,
        (
          blok.highlighted_match?.content
            ?.away_team as ISbStoryData<StoryblokTeam>
        )?.content?.short_name
      )
    ) {
      return {
        highlightedTeam: blok.highlighted_match?.content
          ?.home_team as ISbStoryData<StoryblokTeam>,
        cercleTeam: blok.highlighted_match?.content
          ?.away_team as ISbStoryData<StoryblokTeam>,
      }
    }

    return {}
  }, [
    blok.highlighted_match?.content?.home_team,
    blok.highlighted_match?.content?.away_team,
  ])

  const { translations, locale } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)
  const { stories, isLoading: isStoryblokDataLoading } =
    useStoryblokStories<StoryblokMatch>({
      content_type: 'match',
      starts_with: 'matches/a-team',
      resolve_relations: 'match.away_team,match.home_team',
      sort_by: 'content.matchday:asc',
      filter_query: {
        matchday: {
          gt_date: format(endOfDay(subDays(new Date(), 1)), 'yyyy-MM-dd HH:mm'),
        },
      },
      per_page: 6,
    })

  const matchContent = getMatchesContent(stories, false)

  const upcomingMatchContent =
    matchContent.length > 0 ? matchContent[0] : undefined
  const secondUpcomingMatchContent =
    matchContent.length > 1 ? matchContent[1] : undefined

  const upcomingHomeTeam =
    typeof upcomingMatchContent?.home_team === 'string'
      ? undefined
      : upcomingMatchContent?.home_team?.content
  const upcomingAwayTeam =
    typeof upcomingMatchContent?.away_team === 'string'
      ? undefined
      : upcomingMatchContent?.away_team?.content

  const secondUpcomingHomeTeam =
    typeof secondUpcomingMatchContent?.home_team === 'string'
      ? undefined
      : secondUpcomingMatchContent?.home_team?.content
  const secondUpcomingAwayTeam =
    typeof secondUpcomingMatchContent?.away_team === 'string'
      ? undefined
      : secondUpcomingMatchContent?.away_team?.content

  const futureMatchContent = useMemo(() => {
    return matchContent.length > 1
      ? normalizeMatchContent(matchContent.slice(2, 6))
      : []
  }, [matchContent])

  const isUpcomingMatchedAlreadyPlayed = useMemo(() => {
    if (upcomingMatchContent?.extra_time_full_time) {
      return isAfter(
        new Date(),
        fromZonedTime(
          new Date(upcomingMatchContent?.extra_time_full_time),
          'UTC'
        )
      )
    }

    return (
      upcomingMatchContent?.full_time &&
      isAfter(
        new Date(),
        fromZonedTime(new Date(upcomingMatchContent?.full_time), 'UTC')
      )
    )
  }, [
    upcomingMatchContent?.extra_time_full_time,
    upcomingMatchContent?.full_time,
  ])

  const upComingMatches = useMemo(() => {
    return isUpcomingMatchedAlreadyPlayed || !secondUpcomingMatchContent
      ? futureMatchContent
      : [
          ...normalizeMatchContent([secondUpcomingMatchContent]),
          ...futureMatchContent,
        ]
  }, [
    futureMatchContent,
    isUpcomingMatchedAlreadyPlayed,
    secondUpcomingMatchContent,
  ])

  if (isStoryblokDataLoading) {
    return (
      <StLoadingContainer>
        <Loader />
      </StLoadingContainer>
    )
  }

  console.log('highlighted match', blok.highlighted_match)

  return (
    <StContainer>
      <StFourth>
        <MatchHighlight
          background={blok.background}
          title={blok.highlighted_match_title}
          teamName={highlightedTeam?.content?.name}
          link={{
            href:
              (blok.highlighted_match?.content.live_updates?.length &&
              getHrefFromStoryblokLink(blok.highlighted_match_link)
                ? // Not sure why there is no StoryblokLink but the / needed to be in front for routing
                  `/${blok.highlighted_match.full_slug}`
                : getHrefFromStoryblokLink(blok.highlighted_match_link)) ?? '',
          }}
          linkText={t('report_and_photos')}
          clubLogo={highlightedTeam?.content.logo}
          homeTeamIsCercle={
            (
              blok.highlighted_match?.content
                ?.home_team as ISbStoryData<StoryblokTeam>
            )?.uuid === cercleTeam?.uuid
          }
          homeTeamGoals={blok.highlighted_match?.content?.home_team_goals}
          awayTeamIsCercle={
            (
              blok.highlighted_match?.content
                ?.away_team as ISbStoryData<StoryblokTeam>
            )?.uuid === cercleTeam?.uuid
          }
          awayTeamGoals={blok.highlighted_match?.content?.away_team_goals}
        />
      </StFourth>
      <StHalf>
        {upcomingMatchContent && upcomingMatchContent._uid && (
          <>
            {!isUpcomingMatchedAlreadyPlayed &&
              !matchIsLive(upcomingMatchContent) && (
                <MatchUpcoming
                  title={blok.next_match_title}
                  homeTeamName={upcomingHomeTeam?.name ?? ''}
                  homeTeamLogo={upcomingHomeTeam?.logo}
                  awayTeamName={upcomingAwayTeam?.name ?? ''}
                  awayTeamLogo={upcomingAwayTeam?.logo}
                  matchDate={upcomingMatchContent?.matchday ?? ''}
                  matchTime={upcomingMatchContent?.time}
                  sponsorImage={blok.sponsor_image}
                  sponsorLink={{
                    href: getHrefFromStoryblokLink(blok.sponsor_link) ?? '',
                  }}
                  ticketLink={{
                    href:
                      getHrefFromStoryblokLink(upcomingMatchContent?.tickets) ??
                      '',
                  }}
                  vipLink={{
                    href:
                      getHrefFromStoryblokLink(upcomingMatchContent?.vip) ?? '',
                  }}
                  infoLink={{
                    href:
                      getHrefFromStoryblokLink(upcomingMatchContent?.info) ??
                      '',
                  }}
                  daysShorthand={t('days_shorthand')}
                  locale={locale}
                  liveUpdates={
                    upcomingMatchContent?.live_updates?.length &&
                    upcomingMatchContent?.live_updates?.length > 0
                      ? upcomingMatchContent?.full_slug + '?tab=live'
                      : undefined
                  }
                />
              )}

            {!isUpcomingMatchedAlreadyPlayed &&
              matchIsLive(upcomingMatchContent) && (
                <MatchLiveCard
                  homeTeamName={upcomingHomeTeam?.name ?? ''}
                  homeTeamLogo={upcomingHomeTeam?.logo}
                  homeTeamScore={
                    upcomingMatchContent.home_team_goals === ''
                      ? undefined
                      : upcomingMatchContent.home_team_goals
                  }
                  awayTeamName={upcomingAwayTeam?.name ?? ''}
                  awayTeamLogo={upcomingAwayTeam?.logo}
                  awayTeamScore={
                    upcomingMatchContent.away_team_goals === ''
                      ? undefined
                      : upcomingMatchContent.away_team_goals
                  }
                  matchDate={upcomingMatchContent?.matchday ?? ''}
                  locale={locale}
                  matchTime={upcomingMatchContent?.time}
                  sponsorImage={blok.sponsor_live_image}
                  sponsorLink={{
                    href:
                      getHrefFromStoryblokLink(blok.sponsor_live_link) ?? '',
                  }}
                  ticketLink={{
                    href:
                      getHrefFromStoryblokLink(upcomingMatchContent?.tickets) ??
                      '',
                  }}
                  vipLink={{
                    href:
                      getHrefFromStoryblokLink(upcomingMatchContent?.vip) ?? '',
                  }}
                  infoLink={{
                    href:
                      getHrefFromStoryblokLink(upcomingMatchContent?.info) ??
                      '',
                  }}
                  liveUpdates={
                    upcomingMatchContent?.live_updates?.length &&
                    upcomingMatchContent?.live_updates?.length > 0
                      ? upcomingMatchContent?.full_slug + '?tab=live'
                      : undefined
                  }
                  live="live"
                />
              )}

            {isUpcomingMatchedAlreadyPlayed && (
              <MatchUpcoming
                title={blok.next_match_title}
                homeTeamName={secondUpcomingHomeTeam?.name ?? ''}
                homeTeamLogo={secondUpcomingHomeTeam?.logo}
                awayTeamName={secondUpcomingAwayTeam?.name ?? ''}
                awayTeamLogo={secondUpcomingAwayTeam?.logo}
                matchDate={secondUpcomingMatchContent?.matchday ?? ''}
                matchTime={secondUpcomingMatchContent?.time}
                sponsorImage={blok.sponsor_image}
                sponsorLink={{
                  href: getHrefFromStoryblokLink(blok.sponsor_link) ?? '',
                }}
                ticketLink={{
                  href:
                    getHrefFromStoryblokLink(
                      secondUpcomingMatchContent?.tickets
                    ) ?? '',
                }}
                vipLink={{
                  href:
                    getHrefFromStoryblokLink(secondUpcomingMatchContent?.vip) ??
                    '',
                }}
                infoLink={{
                  href:
                    getHrefFromStoryblokLink(
                      secondUpcomingMatchContent?.info
                    ) ?? '',
                }}
                daysShorthand={t('days_shorthand')}
                locale={locale}
                liveUpdates={
                  secondUpcomingMatchContent?.live_updates?.length &&
                  secondUpcomingMatchContent?.live_updates?.length > 0
                    ? secondUpcomingMatchContent?.full_slug + '?tab=live'
                    : undefined
                }
              />
            )}
          </>
        )}
      </StHalf>
      {upComingMatches && upComingMatches.length > 0 ? (
        <StFourth>
          <MatchCalendarCard
            title={blok.future_matches_title}
            matches={upComingMatches}
            allMatchesLink={{
              href: getHrefFromStoryblokLink(blok.future_matches_link) ?? '',
            }}
            allMatchesText={t('all_matches')}
            locale={locale}
          />
        </StFourth>
      ) : null}
    </StContainer>
  )
}
