import { faFutbol } from '@fortawesome/pro-duotone-svg-icons/faFutbol'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  StButtonLabel,
  StButtonLabelTeriary,
  StButtonIcon,
  StButtonContainer,
  StButtonIconTertiary,
  StButtonSecondary,
  StButtonTertiary,
  StButtonPrimary,
  StButtonContentContainer,
  StButtonLoading,
} from './Button.styled'

import type { ButtonSize, ButtonVariant } from './Button.types'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { ButtonHTMLAttributes, ReactNode, MouseEvent } from 'react'

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: ButtonVariant
  size?: ButtonSize
  prefixIcon?: IconProp
  suffixIcon?: IconProp
  children?: ReactNode | string
  gaEventLabel: string
  gaEventMetaData?: number | string
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  loading?: boolean
}

export const Button = ({
  size = 'normal',
  variant = 'primary',
  disabled = false,
  prefixIcon,
  suffixIcon,
  children,
  gaEventLabel,
  gaEventMetaData = '',
  onClick,
  loading = false,
  ...props
}: ButtonProps) => {
  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'button_click',
        eventLabel: gaEventLabel,
        eventMetadata: gaEventMetaData,
      })
    }

    onClick?.(e)
  }

  const renderButtonContent = () => {
    if (variant == 'tertiary') {
      return (
        <StButtonContainer size={size}>
          <StButtonContentContainer loading={loading} size={size}>
            {prefixIcon && <StButtonIconTertiary icon={prefixIcon} />}
            <StButtonLabelTeriary>{children}</StButtonLabelTeriary>
            {suffixIcon && <StButtonIconTertiary icon={suffixIcon} />}
          </StButtonContentContainer>
          <StButtonLoading loading={loading}>
            <FontAwesomeIcon icon={faFutbol as IconProp} spin />
          </StButtonLoading>
        </StButtonContainer>
      )
    }
    return (
      <StButtonContainer size={size}>
        <StButtonContentContainer loading={loading} size={size}>
          {prefixIcon && <StButtonIcon icon={prefixIcon} />}
          <StButtonLabel>{children}</StButtonLabel>
          {suffixIcon && <StButtonIcon icon={suffixIcon} />}
        </StButtonContentContainer>
        <StButtonLoading loading={loading}>
          <FontAwesomeIcon icon={faFutbol as IconProp} spin />
        </StButtonLoading>
      </StButtonContainer>
    )
  }

  switch (variant) {
    case 'secondary': {
      return (
        <StButtonSecondary
          onClick={handleButtonClick}
          data-event-label={gaEventLabel}
          disabled={disabled || loading}
          {...props}
        >
          {renderButtonContent()}
        </StButtonSecondary>
      )
    }
    case 'tertiary': {
      return (
        <StButtonTertiary
          onClick={handleButtonClick}
          data-event-label={gaEventLabel}
          disabled={disabled || loading}
          {...props}
        >
          {renderButtonContent()}
        </StButtonTertiary>
      )
    }
    default: {
      return (
        <StButtonPrimary
          onClick={handleButtonClick}
          data-event-label={gaEventLabel}
          disabled={disabled || loading}
          {...props}
        >
          {renderButtonContent()}
        </StButtonPrimary>
      )
    }
  }
}
