import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation'
import * as React from 'react'

import { StErrorIcon, StInput, StInputContainer } from './Input.styled'

import type { InputHTMLAttributes } from 'react'

type InputProps = {
  error?: boolean
} & InputHTMLAttributes<HTMLInputElement>

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ error = false, ...props }, ref) => {
    return (
      <StInputContainer>
        <StInput ref={ref} {...props} error={error} />
        {error && <StErrorIcon icon={faTriangleExclamation} />}
      </StInputContainer>
    )
  }
)

Input.displayName = 'Input'
